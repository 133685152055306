import * as React from 'react'
import { Fragment, useState, useRef, useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import "../style/home.less"
import { navigate } from 'gatsby'
import datasImg from "../images/home/data3.png"
import CoSAIImg from '../images/home/CoSAIme.png'
// import video from "../images/home/video.png"
import { Link } from 'gatsby'
import arrowRight from "../images/home/arrow_right.png"
// import shield from "../images/home/adv-icon_g1.png"
// import lock from "../images/home/adv-icon_g2.png"
// import cube from "../images/home/adv-icon_g3.png"
import shield from "../images/home/adv02.png"
import lock from "../images/home/adv01.png"
import cube from "../images/home/adv03.png"
import partnersImg1 from "../images/home/partnersImg1.png"
import partnersImg2 from "../images/home/partnersImg2.png"
import partnersImg3 from "../images/home/partnersImg3.png"
import partnersImg4 from "../images/home/partnersImg4.png"
import partnersImg5 from "../images/home/rcslogo.png"
import robot from "../images/home/robot_g1.png"
import concave from "../images/home/concave.png"
import group1 from "../images/home/Group_g1.png"
import group2 from "../images/home/Group_g2.png"
import group3 from "../images/home/Group_g3.png"
import group4 from "../images/home/Group_g4.png"
import react from "../images/home/icon_react.png"
import docker from "../images/home/icon_docker.png"
import ethereum from "../images/home/icon_ethereum.png"
import keycloak from "../images/home/icon_keycloak.png"
import kubernetes from "../images/home/icon_kubernetes.png"
import presto from "../images/home/icon_presto.png"
import solidity from "../images/home/icon_solidity.png"
import customers1 from "../images/home/customers1.png"
import customers2 from "../images/home/customers2.png"
import customers3 from "../images/home/customers3.png"
import arrowBlue from "../images/home/arrow-blue.png"
import arrowGreen from "../images/home/arrow-green.png"
import arrowGrey from "../images/home/arrow-grey.png"
import shieldImg from "../images/home/shield-img.png"
import smartHome from "../images/home/smart-home.png"
import phone from "../images/home/phone.png"
import footLogo from "../images/home/foot-logo.png"
import video from "../video/video11.mp4"
import robotImg1 from "../images/home/robot_gre1.png"
import robotImg2 from "../images/home/robot_gre2.png"
import robotImg3 from "../images/home/robot_gre3.png"
import partnersImg from "../images/home/rcslogo.png";

import videoButton from "../images/home/videobutton.png"
import Fade from "react-reveal/Fade"
import popupbg from '../images/popup.png'
import colse from '../images/close.png'
const IndexPage = () => {
  const [pressButton, setPressButton] = useState(null)
  const productArr = [
    { img: shield, text: "Real-time situational awareness", p: "This allows managers and executives to quickly identify and address areas ofinefficiency, resulting in increased productivity and a more streamlined workflow." },
    { img: lock, text: "Provide tailored recommendations ", p: "CoSAI can also be fine-tuned to the specific needs and knowledge of a company. This allows the product to provide tailored recommendations and insights that are specific to the company's unique operations and goals." },
    { img: cube, text: "Employee analysis", p: "Byanalyzing data such as performance reviews, emails, and meetings, CoSAI can identify the individuals who are making the greatest contributions to the company's success." }
  ]
  const phoneIcon = [
    presto, solidity, keycloak, react, ethereum, docker, kubernetes
  ]
  const securelyCollaborateArr = [
    {
      id: 0,
      text:"CosAI's feature"
  },
    {
      id: 1,
      img: robotImg1,
      title: "Increased productivity",
      text: "CoSAI can help identify areas of inefficiency within a company"
    },
    {
      id: 2,
      img: robotImg2,
      title: "Better decision-making",
      text: "CoSAI can help CEOs make more informed decisions "
    },
    {
      id: 3,
      img: robotImg3,
      title: "Competitive advantage",
      text: "CEOs can give their companies a competitiveedge in their markets"
    },
    {
      id: 4,
    }
  ]
  // const partnersImgArr = [partnersImg1, partnersImg2, partnersImg3, partnersImg4, partnersImg1]
  const partnersImgArr = [partnersImg]
  const technologyArr = [
    { id: 0, img: group1, h3: "Data privacy and security", p: "Companies may be concerned about the security of their sensitive data when using a tool like CoSAI" },
    { id: 1, img: group2, h3: "Data bias and injustice", p: "There may be concerns about bias in the data analysis and recommendations provided by CoSAI" },
    { id: 2, img: group3, h3: "Overdependence of managers", p: "Some people worry that managers lack critical thinking and decision-making ability" },
    { id: 3, img: group4, h3: "Job displacement among employees", p: "Employees worry that automation will lead to unemployment" }
  ]
  const iconArrTop = [react, ethereum, docker, presto, solidity,keycloak]
  const iconArrBottom = [keycloak,kubernetes,react]
  const satisfiedCustomersArr = [
    { img: customers1, h3: "Smart Cities Toronto", p: "The City of Toronto contracted Sightline Innovation to explore the use of Datatrust technology for various Connected Community use cases." },
    { img: customers2, h3: "Public Health Agency of Canada", p: "PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns." },
    { img: customers3, h3: "Public Health Agency of Canada", p: "PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns." }
  ]
  const [satisfiedListArr, setSatisfiedListArr] = useState(satisfiedCustomersArr)
  const switchList = (direction) => {
    setPressButton(direction)
    if (direction === "right") {
      satisfiedCustomersArr.unshift(satisfiedCustomersArr[satisfiedCustomersArr.length - 1])
      satisfiedCustomersArr.splice(satisfiedCustomersArr.length - 1, 1)
      setSatisfiedListArr(satisfiedCustomersArr)
    } else if (direction === "left") {
      satisfiedCustomersArr.push(satisfiedCustomersArr[0])
      satisfiedCustomersArr.splice(0, 1)
      setSatisfiedListArr(satisfiedCustomersArr)
    }
  }
  // const videoDom = useRef(null)
  // // const [videoPlay, setVideoPlay] = useState(false)
  // const [videoMargin, setVideoMargin] = useState(6.77)
  // const [maskShow, setMaskShow] = useState(false)
  // const [maskTwo, setMaksTwo] = useState(true)
  // const [popupFlag,setPopupFlag] = useState(true)
  // useEffect(() => {
  //   const observer = new IntersectionObserver(entries => {
  //     if (entries[0].isIntersecting) {
  //       videoDom.current.play()
  //     }
  //   }, {
  //     threshold: [0.25, 0.5, 0.75, 1]
  //   })
  //   observer.observe(videoDom.current)
  //   videoDom.current.addEventListener("pause", () => {
  //     setMaskShow(true)
  //   })
  //   videoDom.current.addEventListener("play", () => {
  //     setMaskShow(false)
  //   })
  //   setTimeout(()=>{
  //     setPopupFlag(false)
  //   },3000)
  // }, [])

  // const playVideo = () => {
  //   videoDom.current.play()
  //   setMaskShow(false)
  // }

  return (
    <Fragment>
      <div className='home'>
        <Header />
        <div className='dtaas'>
          <div className='datas_left'>
            <Fade distance={"20%"} top duration={1300}  >
              <h3 style={{ overflow: "hidden" }}>CoSAI</h3>
            </Fade>
            <span>is a productivity tool</span>
            <p>
            CoSAI is a productivity tool that uses advanced technology to provide an automatedoverall assessment of a company's productivity.By utilizing GPT and Data Trust/Governance(DTaaS).
            </p>
            <div onClick={() => navigate("/contactUs")} className='button'>Subscribe to us</div>
          </div>
          <div className='datas_right'>
            <img src={datasImg} />
          </div>
        </div>
        {/* <div className='video' style={{ margin: `0 ${videoMargin}` }} >
          <div className='mask mask2' style={{ display: maskTwo ? "block" : "none" }}>
            <div onClick={() => setMaksTwo(false)} className='button'>
              <img src={videoButton} alt="" />
            </div>
          </div>
          <div className='mask' style={{ display: maskShow ? "block" : "none" }}>
            <div onClick={playVideo} className='button'>
              <img src={videoButton} alt="" />
            </div>
          </div>
          <div className='video_box' >
            {/* <img src={video} /> 
            <video ref={videoDom} muted width="100%" controls   >
              <source src={video} type="video/mp4" ></source>
            </video>
          </div>
        </div> */}
        {/* Datatrust for a connected community */}
        <div className='datatrust'>
          {/* top_part */}
          <ul className='top_part'>
            <li>

              <Fade distance={"20%"} top duration={1300} >
                <h3>AI tools to rapidly improve company efficiency</h3></Fade>
            </li>
            <li>
              <p>CoSAI is able to analyze a company's enterprise data, including meetings, emails,
and performance reviews, to gain a deeper understanding of where time is being wasted and who the most valuable members of the company are.</p>
              <div onClick={() => navigate("/company")}>
                <span>About us</span>
                <img src={arrowRight} />
              </div>
            </li>
          </ul>
          {/* bottom_part */}
          <ul className='bottom_part'>
            {
              productArr.map((item, index) => {
                return (
                  <li key={index}>
                    <div><img src={item.img} /></div>
                    <h3>{item.text}</h3>
                    <p>{item.p}</p>
                  </li>
                )
              })
            }
          </ul>
          {/* The  partners with us */}
        </div>
        {/* The  partners with us */}
        <div className='partners'>
          <div className='partners_text'>
            <Fade distance={"20%"} top duration={1300}><h3>The  partners with us</h3></Fade>

            <div>Provide comprehensive data protection for <span style={{color:'#36CCB1'}}>more than 10 million customers</span></div>
          </div>
          <ul className='partners_img'>
            {
              partnersImgArr.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item} />
                  </li>
                )
              })
            }

          </ul>
        </div>
        {/* Sightline Innovation Security Products */}
        <div className='security_products'>
          <div className='security_products_text'>
            <Fade distance={"20%"} top duration={1300} ><h3>Chief of Staff AI is a powerful productivity tool</h3></Fade>
            <div>Create better technology with open source</div>
            <p>
            We can help companies gain a deeper understanding of their operations and identify areas of inefficiency.
            </p>
          </div>
          <ul className='robot'>
            <li>
              <img src={robot} />
            </li>
            <li>
              <span style={{color:'#36CCB1'}}>For decades</span>
              <Fade distance={"20%"} top duration={1300} ><h3>Why would CEOs want to buy the Chief of Staff AI product?</h3></Fade>
              <p>Chief of Staff AI can provide CEOs with a comprehensive view of their companies, allowing them to make better decisions and improve overall performance.</p>
            </li>
            <li>
              <div onClick={() => navigate("/productList")}>
                <img src={arrowRight} />
              </div>
            </li>
          </ul>
          <div className='securely'>
            {/* <h6>Sightline's feature</h6> */}
            <ul>
              {
                securelyCollaborateArr.map(item => {
                  return (
                    <li key={item.id}>
                      <div className='img'><img src={item.img} /></div>
                      <h3>{item.title}</h3>
                      <p>{item.text}</p>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        {/* Our best in class technology   */}
        <div className='technology'>
          <Fade distance={"20%"} top duration={1300}><h3>Use CosAI to solve potential problems</h3></Fade>
          {/* <p><span> to identify cyber attackers </span>, their plans and methods</p> */}
          <p> help managers and executives <span style={{color:'#36CCB1',fontFamily:'Gilory'}}>take steps to improve overall productivity</span> </p>

          <ul className='technology_list'>
            {
              technologyArr.map(item => {
                return (
                  <li key={item.id}>
                    <div className='img'><img src={item.img} /></div>
                    <div className='content'>
                      <h3>{item.h3}</h3>
                      <p>{item.p}</p>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          <div className='technology_icon'>
            <ul>
              {
                iconArrTop.map((item, index) => {
                  return (
                    <li key={index}><img src={item} /></li>
                  )
                })
              }
            </ul>
            <ul>
              {
                iconArrBottom.map((item, index) => {
                  return (
                    <li key={index}><img src={item} /></li>
                  )
                })
              }
            </ul>
            <ul className='phone_icon'>
              {
                phoneIcon.map((item, index) => {
                  return (
                    
                    <li key={index}><img src={item} /></li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        {/* Satisfied customers */}
        <div className='satisfied_customers'>
          <div className='satisfied_customers_text'>
            <Fade distance={"20%"} top duration={1300}><h3>Satisfied customers</h3></Fade>
            <p>CoSAI can help companies improve competitiveness and increase revenue.</p>
            <div onClick={() => navigate("/solutions")} className='more'>More<img src={arrowRight} /></div>
          </div>
          <div className='satisfied_customers_list'>
            <ul>
              {
                satisfiedListArr.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className='img'><img src={item.img} /></div>
                      <div>
                        <h3>{item.h3}</h3>
                        <p>{item.p}</p>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className='bottom'>
            <div onClick={() => switchList("left")} className={pressButton === "left" ? "press" : "default"}>
              {
                pressButton === "left" ?
                  <img src={arrowGrey} style={{ transform: "rotate(180deg)" }} /> :
                  <img src={arrowGreen} />
              }
            </div>
            <div onClick={() => switchList("right")} className={pressButton === "right" ? "press" : "default"}>
              {
                pressButton === "right" ?
                  <img src={arrowGrey} /> :
                  <img style={{ transform: "rotate(180deg)" }} src={arrowGreen} />
              }
            </div>
          </div>
        </div>
        {/* What you can do with our all-in-one platform */}
        <div className='securely_collaborate'>
          <Fade distance={"20%"} top duration={1300} ><h3 id='platform' >CoSAI may save a lot of money for the company</h3></Fade>
          <text>By identifying areas of inefficiency and waste within a company</text>
          <div className='securely_collaborate_content'>
            <div><img src={shieldImg} /></div>
            <Fade delay={100} distance={"20%"} top duration={1300}><h3>Simplify workflow</h3></Fade>
            <p>CoSAI can identify valuable members in the company<br />
            and help managers make more intelligent decisions<br />
            and allocate resources</p>
            <div onClick={() => navigate("productList")} className='more'>More<img src={arrowRight} /></div>
          </div>
          {/* <div className='phone'>
            <img src={phone} />
          </div> 
          <div className='smart_home'>
            <img src={smartHome} />
          </div>*/}
        </div>
        <div className='footer_logo'>
          <Fade distance={"20%"} top duration={1300}><h3>Now help enterprises improve production capacity</h3></Fade>
          <div onClick={() => navigate("/contactUs")} style={{background:'#36CCB1'}}>Get Started</div>
        </div>
      </div>
      <Footer />
      {/* <div className="popup" style={{display:popupFlag?"block":"none"}}>
            <div className='content'>
                  <img className='bgimg' src={popupbg} alt="" />
                  <h3>Everything You Need to Vote</h3>
                  <p>Vote with or delegate your CTIO Tokens to help protect the integrity of the Maker protocol</p>
                  <Link target="_blank" to="https://www.chaintokenomics.io"><div className="button">Get Started</div></Link>
                  <img onClick={()=>setPopupFlag(false)} className='close' src={colse} alt="" />
            </div>
      </div> */}
    </Fragment>
  )
}

export default IndexPage
